/* ./src/tailwind.css */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply min-h-screen text-base text-white bg-gray-800 antialiased;
    background-image: linear-gradient(
      to bottom,
      theme('colors.gray.800') 15%,
      theme('colors.gray.900') 100%
    );
  }

  .container {
    @apply max-w-7xl mx-auto px-5 lg:px-10;
  }

  .input-remove-arrows::-webkit-outer-spin-button,
  .input-remove-arrows::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .input-remove-arrows[type='number'] {
    -moz-appearance: textfield;
  }

  a:focus,
  a:focus-visible,
  button:focus,
  button:focus-visible {
    @apply outline-none ring-2 ring-current ring-opacity-75 ring-offset-2 ring-offset-gray-800;
  }

  a:focus:not(:focus-visible),
  button:focus:not(:focus-visible) {
    @apply ring-0 ring-offset-0;
  }
}
